<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="true"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>مدخلات</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="بحث..."
              />
              <b-button
                v-if="showButtonAdd && $can('create', 'evaluationRequest')"
                variant="primary"
                to="/evaluation-request/create"
              >
                <span class="text-nowrap">إضافة {{ $t('evaluation_request') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refEvaluationListTable"
        class="position-relative"
        :items="fetchEvaluations"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="لم يتم العثور على سجلات مطابقة"
        :sort-desc.sync="isSortDirDesc"
      >
      <template #cell(reference)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <a :href="`/evaluation/${data.item.id}/view`">{{ data.value }}</a>
      </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'evaluations-view',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('inspect', 'evaluation')"
              :to="{
                name: 'evaluations-review',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('review', 'evaluation')"
              :to="{
                name: 'evaluations-review',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">مراجعة</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('delete', 'evaluationRequest')"
              @click="confirmDelete(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('inspect', 'evaluation')"
              @click="createCopy(data.item.id )"
            >
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">انشاء نسخة</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item @click="download(data.item)">
              <feather-icon icon="DownloadCloudIcon" />
              <span class="align-middle ml-50">تحميل</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            >عرض من {{ dataMeta.from }} إلى {{ dataMeta.to }} من
              {{ dataMeta.of }} مدخلات</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEvaluation"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import EvaluationListFilters from './EvaluationListFilters.vue';
import useEvaluationList from './useEvaluationList';
import evaluationStore from '../evaluationStore';

export default {
  components: {
    EvaluationListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  props: {
    showButtonAdd: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // Define an asynchronous function to create a copy of the evaluation and redirect to the new ID
    createCopy(id) {
      store.dispatch('evaluation/createCopy', { id })
        .then((response) => {
          // Extract the new ID from the response
          const newId = response.data.data.id;
          this.$toast.success('نسخة جديدة تم إنشاؤها بنجاح');
          // Redirect to the 'evaluations-review' route with the new ID
          router.push({
            name: 'evaluations-review',
            params: { id: newId },
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error('حدث خطأ  ');
        });
    },

    download(evaluation) {
      this.$toast.info('جاري التنزيل...', {
        autoHide: false,
        appendToast: ToastificationContent,
      });
      store.dispatch('evaluation/downloadEvaluation', { id: evaluation.id })
        .then((response) => {
          this.downloadFile(response.data, evaluation.reference);
          this.$toast.success('تم التنزيل بنجاح');
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error('حدث خطأ اثناء التنزيل');
        });
    },
    downloadFile(data, filename, type = 'application/pdf') {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${filename}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    deleteEvaluation(evaluation) {
      store
        .dispatch('evaluation/deleteEvaluation', {
          id: evaluation.id,
        })
        .then((response) => {
          this.$emit('refetch-data');
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            evaluationData.value = undefined;
          }
        });
    },
    confirmDelete(evaluation) {
      this.$bvModal
        .msgBoxConfirm(
          `يرجى تأكيد أنك تريد حذف العنصر  ${evaluation.id}.`,
          {
            title: 'يرجى التأكيد',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'نعم',
            cancelTitle: 'لا',
            cancelVariant: 'outline-warning',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then((value) => {
          if (value) {
            this.deleteEvaluation(evaluation);
          }
        });
    },
  },
  setup() {
    const EVALUATION_STORE_MODULE_NAME = 'evaluation';

    // Register evaluation
    if (!store.hasModule(EVALUATION_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATION_STORE_MODULE_NAME,
        evaluationStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATION_STORE_MODULE_NAME)) store.unregisterModule(EVALUATION_STORE_MODULE_NAME);
    });

    const statusOptions = [
      {
        label: 'في الانتظار',
        value: 'في الانتظار',
      },
      {
        label: 'مقبول',
        value: 'مقبول',
      },
      {
        label: 'مرفوض',
        value: 'مرفوض',
      },
    ];

    const {
      fetchEvaluations,
      tableColumns,
      perPage,
      currentPage,
      totalEvaluation,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEvaluationListTable,
      refetchData,

      // UI
      statusFilter,
    } = useEvaluationList();

    return {

      fetchEvaluations,
      tableColumns,
      perPage,
      currentPage,
      totalEvaluation,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEvaluationListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      statusOptions,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
