var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mentionable",staticStyle:{"position":"relative"}},[_vm._t("default"),_c('VDropdown',_vm._b({ref:"popper",staticClass:"popper",staticStyle:{"position":"absolute"},style:(_vm.caretPosition ? {
      top: ((_vm.caretPosition.top) + "px"),
      left: ((_vm.caretPosition.left) + "px"),
    } : {}),attrs:{"placement":_vm.placement,"shown":!!_vm.key,"triggers":[],"auto-hide":false,"theme":_vm.theme},scopedSlots:_vm._u([{key:"popper",fn:function(){return [(!_vm.displayedItems.length)?_c('div',[_vm._t("no-result",[_vm._v(" No result ")])],2):_vm._l((_vm.displayedItems),function(item,index){return _c('div',{key:index,staticClass:"mention-item",class:{
            'mention-selected': _vm.selectedIndex === index,
          },on:{"mouseover":function($event){_vm.selectedIndex = index},"mousedown":function($event){return _vm.applyMention(index)}}},[_vm._t(("item-" + (_vm.key || _vm.oldKey)),[_vm._t("item",[_vm._v(" "+_vm._s(item.label || item.value)+" ")],{"item":item,"index":index})],{"item":item,"index":index})],2)})]},proxy:true}],null,true)},'VDropdown',_vm.$attrs,false),[_c('div',{style:(_vm.caretPosition ? {
        height: ((_vm.caretPosition.height) + "px"),
      } : {})})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }