<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <feather-icon
          size="24"
          class="mr-1"
          icon="ListIcon"
        />
        <b-card-title class="ml-25">
          الخط الزمني لطلب التقييم
        </b-card-title>
      </div>
    </b-card-header>

    <!-- timeline -->
    <b-card-body style="max-height: 420px;overflow: auto;">
      <app-timeline v-if="evaluationRequest.activities">
        <app-timeline-item
          v-for="(activity, index) in evaluationRequest.activities"
          :key="index"
          :variant="getVariant(activity)"
        >
          <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
            <h6>{{ getTitle(activity) }}</h6>
            <small class="timeline-item-time text-nowrap text-muted ml-1">{{ getTime(activity) }}</small>
          </div>
          <p>{{ activity.description }}</p>
        </app-timeline-item>
      </app-timeline>
      <div
        v-else
        class="text-center"
      >
        <h6>لا توجد أي أحداث مسجلة</h6>
      </div>
      <!--/ timeline -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BImg, BMedia, BMediaBody, BMediaAside, BAvatar, BAvatarGroup, VBTooltip,
} from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import moment from 'moment';
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    evaluationRequest: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    getVariant(activity) {
      switch (activity.event) {
        case 'created':
          return 'success';
        case 'updated':
          return 'info';
        case 'deleted':
          return 'danger';
        default:
          return 'primary';
      }
    },
    getTitle(activity) {
      switch (activity.event) {
        case 'created':
          return 'تمت إضافة طلب التقييم';
        case 'updated':
          return 'تم تعديل طلب التقييم';
        case 'deleted':
          return 'تم حذف طلب التقييم';
        default:
          return 'تم تعديل طلب التقييم';
      }
    },

    getTime(activity) {
      return moment(activity.created_at).format('YYYY-MM-DD hh:mm');
    },
  },
};
</script>
