<template>
  <div>
    <b-card
      v-if="evaluationRequest"
      no-body
      class="border-primary"
    >
      <b-card-header
        class="d-flex justify-content-between align-items-center pt-75 pb-25"
      >
        <h5 class="mb-0">
          تفاصيل طلب التقييم
        </h5>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-alert
              :show="evaluators !== null && evaluators.length === 0"
              variant="danger"
              class="mb-0"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                لا يوجد مقييمين مسجلين. يرجى إضافة مقييمين لاسناد مهام التقييم.
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <ul class="list-unstyled my-1">
              <li>
                <span
                  class="align-middle"
                >رقم المعاملة: {{ evaluationRequest.reference }}</span>
              </li>
              <li>
                <span
                  class="align-middle"
                >موضوع التقييم: {{ getEvaluationRequestSubject() }}</span>
              </li>
              <li>
                <span
                  class="align-middle"
                >التقييم لصالح: {{evaluation_feature('client_info').value.client_name}} </span>
              </li>
              <!-- <li>
                <span
                  class="align-middle"
                >إسم المالك:</span>
              </li> -->
              <li>
                <span
                  class="align-middle"
                >تاريخ طلب التقييم: {{ formatDate(evaluationRequest.created_at) }}</span>
              </li>
              <li>
                <span class="align-middle">
                  حالة طلب التقييم:
                  <b-badge
                    :variant="
                      evaluationRequest.status == 'ملغى'
                        ? 'light-danger'
                        : 'light-primary'
                    "
                  >
                    {{ evaluationRequest.status }}
                  </b-badge></span>
              </li>
            </ul>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <ul class="list-unstyled my-1">
              <li>
                <span class="align-middle"> عدد الملفات المرفقة #: 0</span>
              </li>
              <li>
                <span class="align-middle">عدد الصور المرفقة #: 0</span>
              </li>
              <li>
                <span class="align-middle">
                  تم الرفع على نظام قيمة:
                  <b-badge variant="light-info">لم يتم الرّفع </b-badge>
                </span>
              </li>
              <li>
                <span class="align-middle">
                  مبلغ التقييم الكلى: لم يتم التقييم
                </span>
              </li>
              <li>
                <span class="align-middle">
                  تاريخ الاعتماد النهائى: لم يتم التقييم
                </span>
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
          <evaluation-request-ownership v-if="evaluation_feature('ownership_info').value != ''" :ownershipInfo="evaluation_feature('ownership_info').value" />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col
            cols="12"
            md="12"
          >
            <b-button
              v-if="evaluators && evaluators.length > 0 && $can('create', 'evaluationRequest')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-assign-evaluator
              class="btn-sm mr-2"
              variant="primary"
            >
              <feather-icon
                icon="BriefcaseIcon"
                class="mr-25"
              />
              <span>إسناد التّقييم</span>
            </b-button>
            <b-button
              v-if="evaluators && evaluators.length > 0 && $can('inspect', 'evaluation')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn-sm mr-2"
              variant="primary"
              :to="`/evaluation/create/${evaluationRequest.id}`"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>تقييم العقار</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-assign-evaluator"
      ref="modal-assign-evaluator"
      title="إسناد التّقييم"
      ok-title="إسناد التّقييم"
      cancel-title="إلغاء"
      cancel-variant="outline-secondary"
      @ok="handleAssignEvaluator"
    >
      <b-form>
        <b-form-group
          label="إسم المقيم"
          label-for="vue-select"
        >
          <v-select
            id="vue-select"
            v-model="evaluationRequest.evaluated_by"
            dir="rtl"
            :options="evaluators"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BButton,
  BRow,
  BCol,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BAlert,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import * as moment from 'moment';
import { ref, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import evaluationRequestStore from '../evaluationRequestStore';
import EvaluationRequestOwnership from './EvaluationRequestOwnership.vue';

export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BRow,
    BCol,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BAlert,
    EvaluationRequestOwnership,
  },
  props: {
    evaluationRequest: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: 'USA',
      option: ['USA', 'Canada', 'Mexico'],
    };
  },
  methods: {
    evaluation_feature(name) {
      const feature = this.evaluationRequest.features.find(
        (feature) => feature.feature.name === name,
      );
      return feature ? feature : { value: '' };
    },
    getEvaluationRequestSubject() {
      return `${this.evaluationRequest.property.classification.name} ${this.evaluationRequest.property.type.name} في ${this.evaluationRequest.property.city.name_ar} ${this.evaluationRequest.property.district.name_ar}`;
    },
    handleAssignEvaluator(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.updateEvaluationRequest({ evaluator_id: this.evaluationRequest.evaluated_by.id });
      this.$nextTick(() => {
        this.$refs['modal-assign-evaluator'].hide();
      });
    },
    updateEvaluationRequest(data) {
      this.$store
        .dispatch('evaluationRequest/assignEvaluator', { evaluationRequestData: data, id: this.evaluationRequest.id })
        .then((response) => {
          this.$toast.success(response.data.message);
          this.$emit('refresh');
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
    moment() {
      return moment();
    },
    formatDate(date, format = 'YYYY-MM-DD hh:mm') {
      return moment(date).format(format);
    },
    assignEvaluationRequest() {},
  },
  setup(props) {
    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluationRequest';

    // Register evaluationRequest
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationRequestStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
    });
    const evaluators = ref(null);
    store
      .dispatch('evaluationRequest/fetchEvaluators')
      .then((response) => {
        const { data } = response.data;
        evaluators.value = data.map((c) => ({
          label: c.name,
          value: c.id,
          ...c,
        }));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          evaluators.value = undefined;
        }
      });
    return {
      evaluators,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
