<template>
  <div class="chats">
    <div
      v-for="(message, index) in chatData"
      :key="message.user_id+String(index)"
      class="chat"
      :class="{'chat-left': message.user_id === userData.id}"
    >
      <div class="chat-avatar">
        {{ message.user.name }}<br>
        <b-badge
          v-if="message.user.id === userData.id"
          variant="light-primary"
          size="sm"
        >
          {{ $t(userData.role) }}
        </b-badge>
        <b-badge
          v-else
          variant="light-primary"
          size="sm"
        >
          {{ $t(message.user.roles[0].name) }}
        </b-badge>
      </div>
      <div class="chat-body">
        <div class="chat-content">
          <p>{{ message.message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import { BAvatar, BBadge } from 'bootstrap-vue';

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    chatData: {
      type: Array,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>
