<template>
  <div v-if="evaluationRequest">
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <evaluation-request-details-card
          :evaluation-request="evaluationRequest"
          @refresh="refresh"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <evaluation-request-timeline-card
          :evaluation-request="evaluationRequest"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <evaluation-request-chat-card
          :evaluation-request="evaluationRequest"
        />
      </b-col>
    </b-row>
    <b-card
      no-body
      class="mb-1"
    >
      <div class="card-header">
        <!-- Title -->
        <div>
          <b-card-title>التقييمات</b-card-title>
        </div>
      </div>
      <div class="m-2">
        <evaluation-list
          :show-button-add="false"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import {
  BAlert,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BButton,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BRow,
  BCol,
  BTable,
  BProgress,
  BBadge,
  BCard,
  BCardTitle,
} from 'bootstrap-vue';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { heightTransition } from '@core/mixins/ui/transition';
import evaluationRequestStore from '../evaluationRequestStore';
import EvaluationRequestDetailsCard from './EvaluationRequestDetailsCard.vue';
import EvaluationRequestChatCard from './EvaluationRequestChatCard.vue';
import EvaluationRequestTimelineCard from './EvaluationRequestTimelineCard.vue';
import EvaluationList from '../../evaluation/evaluation-list/EvaluationList.vue';

export default {
  directives: {
    Ripple,
  },
  components: {
    EvaluationRequestDetailsCard,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BTable,
    BProgress,
    BBadge,
    BCard,
    BAlert,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    EvaluationRequestChatCard,
    EvaluationRequestTimelineCard,
    EvaluationList,
  },
  mixins: [alertMessageMixin, heightTransition],
  data() {
    return {
      id: router.currentRoute.params.id,
    };
  },
  methods: {
    refresh() {
      this.fetchEvaluationRequest().then(() => {
        this.$forceUpdate();
      });
    },
    async fetchEvaluationRequest() {
      this.$store
        .dispatch('evaluationRequest/fetchEvaluationRequest', {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          const { data } = response.data;
          this.evaluationRequest = data;
          this.showUpdateCostsForm = false;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            evaluationRequest.value = undefined;
          }
        });
    },
  },
  setup() {
    const EVLUATION_REQUEST_STORE_MODULE_NAME = 'evaluationRequest';

    // Register evaluationRequest
    if (!store.hasModule(EVLUATION_REQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVLUATION_REQUEST_STORE_MODULE_NAME,
        evaluationRequestStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVLUATION_REQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVLUATION_REQUEST_STORE_MODULE_NAME);
    });
    const evaluationRequest = ref(null);
    const initialEvaluationRequestData = ref(null);

    store
      .dispatch('evaluationRequest/fetchEvaluationRequest', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { data } = response.data;
        evaluationRequest.value = data;
        initialEvaluationRequestData.value = JSON.parse(
          JSON.stringify(evaluationRequest.value),
        );
      })
      .catch((error) => {
        if (error.response.status === 404) {
          evaluationRequest.value = undefined;
        }
      });

    const resetevaluationRequestData = () => {
      evaluationRequest.value = JSON.parse(
        JSON.stringify(initialEvaluationRequestData.value),
      );
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetevaluationRequestData,
    );
    return {
      evaluationRequest,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
