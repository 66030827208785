<template>
  <div class="evaluation-request-ownership">
    <h5 class="mb-1">تفاصيل الملكية:</h5>
    <div class="table-container">
      <table>
        <thead class="list-unstyled my-1">
          <tr>
            <th>رقم هوية المالك</th>
            <th>اسم المالك</th>
            <th>رقم هاتف المالك</th>
            <th>نسبة التملك</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="owner in ownershipInfo" :key="owner.owner_id">
            <td>{{ owner.owner_cin }}</td>
            <td>{{ owner.owner_name }}</td>
            <td>{{ owner.owner_phone }}</td>
            <td>{{ owner.ownership_percentage }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "EvaluationRequestOwnership",
  props: {
    ownershipInfo: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.evaluation-request-ownership {
  font-family: inherit; 
  font-size: inherit; 
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: none; 
  border-left: 1px solid #e2e8f0; 
  border-right: 1px solid #e2e8f0; 
  border-radius: 0.358rem; 
}

th,
td {
  padding: 10px;
  text-align: center;
  border-top: 1px solid #e2e8f0; 
  border-bottom: 1px solid #e2e8f0; 
}
thead {
  background-color: #F5F5F5; 
}
</style>
